// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentUrl: "http://localhost:4200",
  userUrl: "https://user-uat.denariusfinance.es",
  coreUrl: "https://core-uat.denariusfinance.es",
  companyUrl: "https://company-uat.denariusfinance.es",
  dmsUrl: " https://dms-uat.denariusfinance.es",
  contactUrl: "https://contact-uat.denariusfinance.es",
};


/*
 * For easier debugging in development mode, you can import the following file
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

/* ng serve -c uat */