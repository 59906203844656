import { Injectable } from "@angular/core";
import { InfoBusinessComponent } from "src/app/modules/business/client-business/info-business/info-business.component";
import { IconButton, Link } from "../../models/headers/link.model";
import { SpinnerComponent } from "src/app/shared/layout/spinner/spinner.component";
import { EmployeeComponent } from "src/app/modules/business/client-business/employee/employee.component";
import { ConfigComponent } from "src/app/modules/business/client-business/config/config.component";
import { ProductsComponent } from "src/app/modules/business/client-business/products/products.component";
import { CardsComponent } from "src/app/modules/cards/cards.component";
import { DashboardBusinessComponent } from "src/app/modules/business/dashboard-business/dashboard-business.component";
import { DashboardIndividualComponent } from "src/app/modules/individual/dashboard-individual/dashboard-individual.component";
import { PersonalInfoComponent } from "src/app/modules/individual/client-individual/personal-info/personal-info.component";
import { UserCardsComponent } from "src/app/shared/components/user-cards/user-cards.component";
import { UserAccountsComponent } from "src/app/shared/components/user-accounts/user-accounts.component";
import { UserDocumentsComponent } from "src/app/shared/components/user-documents/user-documents.component";
import { CardsTableComponent } from "src/app/shared/components/user-cards/cards-table/cards-table.component";
import { TableEmployeeComponent } from "src/app/modules/business/client-business/employee/table-employee/table-employee.component";
import { CreateEmployeeComponent } from "src/app/modules/business/client-business/employee/create-employee/create-employee.component";
import { DetailEmployeeComponent } from "src/app/modules/business/client-business/employee/detail-employee/detail-employee.component";
import { UserData } from "../../models/user/userData";
import { PlansComponent } from "src/app/modules/contracting/plans/plans.component";
import { MessagesComponent } from "src/app/modules/business/client-business/messages/messages.component";



@Injectable()
export class HeaderLinksService {

    getCompanyDashboard() {
        return [
            new Link(
                DashboardBusinessComponent,
                "Info"
            ),

/*             new Link(
                CardsComponent,
                "Tarjetas"
            ), */
        ]
    }

    getCompany(userData:any) {
        return [
            new Link(
                InfoBusinessComponent,
                "Info",
                userData
            ),

            new Link(
                EmployeeComponent,
                "Usuarios",
                userData
            ),

            new Link(
                UserAccountsComponent,
                "Cuentas",
                userData
            ),

            new Link(
                UserCardsComponent,
                "Tarjetas",
                userData
            ),

            new Link(
                UserDocumentsComponent,
                "Documentos",
                userData
            ),

            new Link(
                ProductsComponent,
                "Contrataciones",
                userData
            ),

            new Link(
                ConfigComponent,
                "Configuración",
                userData
            ),

            new Link(
                MessagesComponent,
                "Notificaciones",
                userData
            )
        ]
    }

    getFreelance() {
        return [
            new Link(
                SpinnerComponent,
                "Info"
            ),
            new Link(
                SpinnerComponent,
                "Cuentas"
            )
        ]
    }

    getIndividualDashboard(){
        return [
            new Link(
                DashboardIndividualComponent,
                "Info",
            ),
/*             new Link(
                CardsComponent,
                "Tarjetas",
            ) */
        ]
    }
    getIndividual(userData: UserData) {
        return [
            new Link(
                PersonalInfoComponent,
                "Info",
                userData
            ),
            
            new Link(
                UserAccountsComponent,
                "Cuentas",
                userData
            ),

            new Link(
                UserCardsComponent,
                "Tarjetas",
                userData
            ),

            new Link(
                UserDocumentsComponent,
                "Documentos",
                userData
            ),

            new Link(
                ProductsComponent,
                "Contrataciones",
                userData
            ),

            new Link(
                ConfigComponent,
                "Configuración",
                userData
            ),

            new Link(
                MessagesComponent,
                "Notificaciones",
                userData
            )
        ]
    }


    getCardsIconButtons() {
        return [
            new IconButton(
                CardsTableComponent,
                "view_list",
                "Listado de tarjetas"
            )
        ]
    }

    getEmployeesIconButtons() {
        return [
            new IconButton(
                TableEmployeeComponent,
                "view_list",
                "Listado de usuarios"
            ),
            new IconButton(
                CreateEmployeeComponent,
                "person_add",
                "Crear nuevo usuario"
            )
        ]
    }
}